import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"

export default function GetSpecialFeatureImage(specialFeatureName) {
  const allSpecialFeature = useStaticQuery(graphql`
    {
      allNewtSpecialFeature {
        edges {
          node {
            coverImage {
              src
            }
            specialFeatureName
          }
        }
      }
    }
  `)

  const nodes = allSpecialFeature.allNewtSpecialFeature.edges.filter(data => {
    return (
      data.node.specialFeatureName === specialFeatureName.specialFeatureName
    )
  })

  const spfPost = nodes[0].node
  return <ImageFromNewt src={spfPost !== "" ? spfPost.coverImage.src : ""} />
}
