import React from "react"
import Layout from "../components/Layout/Layout"
import { useStaticQuery, graphql } from "gatsby"

import InstagramFeed from "../components/Atomic/InstagramFeed"
import ListedRecipes from "../components/Atomic/Top/ListedRecipes"
import MainVisual from "../components/Atomic/Top/MainVisual"

import HeaderTitle from "../components/Atomic/HeaderTitle"
import Seo from "../components/Layout/Seo"
import { Link } from "gatsby"

export default function Home() {
  const allRecipeData = useStaticQuery(graphql`
    query LatestRecipeQuery {
      allNewtRecipe {
        edges {
          node {
            eyecatch {
              src
            }
            slug
            recipeMeta {
              recipeName
              cookTime
              prepTime
            }
            recipeTitle
            categoryTags {
              tags {
                tagName
              }
              category {
                categoryName
              }
            }
            youtubeLink
          }
        }
      }
    }
  `)
  const latestData = allRecipeData.allNewtRecipe.edges.map(data => {
    return data
  })

  // const recipe__filtered_sweets = allRecipeData.allNewtRecipe.edges.filter(
  //   data => {
  //     if (data.node.categoryTags.category === null) {
  //       return
  //     }
  //     return data.node.categoryTags.category.categoryName === "スイーツ"
  //   }
  // )

  return (
    <div>
      <Layout>
        <MainVisual recipeData={allRecipeData.allNewtRecipe} />
        <ListedRecipes
          recipesData={latestData.slice(0, 6)}
          title="Latest Recipes"
          subTitle={"新着レシピ"}
        />
        {/* <ListedRecipes
          recipesData={recipe__filtered_sweets.slice(0, 6)}
          title="Sweets"
          subTitle={"スイーツ"}
        /> */}
        <section className="bg-[#f9f9f9] base-section-padding">
          <div className="basic-wrapper">
            <HeaderTitle title="Follow Us!" className="font-alphabet text-center md:text-left" />
            <InstagramFeed />
          </div>
          <div className="basic_wrapper text-center mb-32 px-6">
            <Link to="/about" className="underline">
              ohmycottieのレシピについて
            </Link>
            <p>
              本サイトに関してのお問い合わせは
              <a
                href="https://raguni.jp/contact/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                こちら
              </a>
              から。
            </p>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export const Head = () => <Seo />
