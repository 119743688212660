import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import BlackButton from "./button/BlackButton"

export default function InstagramFeed() {
  const data = useStaticQuery(graphql`
    query InstaQuery {
      allInstaNode(filter: { mediaType: { ne: "VIDEO" } }) {
        edges {
          node {
            type
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  let arrayOfInstaImages = data.allInstaNode.edges
  let latestImages = arrayOfInstaImages.slice(0, 12)

  return (
    <div className="pb-16">
      <div className="grid grid-cols-3 md:grid-cols-4 md:gap-4 gap-2 mb-10">
        {latestImages.map((item, index) => {
          return (
            <>
              <a
                href={`https://instagram.com/p/${item.node.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GatsbyImage
                  image={getImage(
                    item.node.localFile.childImageSharp.gatsbyImageData
                  )}
                  objectFit="contain"
                  className={`${item.node.media_type} shadow-materialHover rounded-lg`}
                  alt="ohmycottie instagram"
                  key={index}
                />
              </a>
            </>
          )
        })}
      </div>
      <div className="flex justify-center">
        <BlackButton
          link="https://instagram.com/ohmycottie"
          text="Visit Our Instagram"
          isAlphabet={true}
        />
      </div>
    </div>
  )
}
