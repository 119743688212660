import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import IconMenuBar from "../../Layout/IconMenuBar"
import BasicButton from "../../Atomic/button/BasicButton"
import GrayBadge from "../Badge/GrayBadge"
import MakerTitle from "../Typography/MakerTitle"
import FeelsLike from "../../Atomic/FeelsLike"
import CardSecondary from "../Post/CardSecondary"
import GetRecipeImage from "./GetRecipeImage"
import GetLifestyleImage from "./GetLifestyleImage"
import GetSpecialFeatureImage from "./GetSpecialFeatureImage"

const Logo = "../../../images/favicons/logo@2x.png"

export default function MainVisual({ recipeData }) {
  const topContents = useStaticQuery(graphql`
    query MyQuery {
      allNewtSelection {
        edges {
          node {
            topView {
              selection_recipe {
                recipeTitle
                slug
                categoryTags {
                  tags
                }
              }
            }
            pickUp {
              selection_lifestyle {
                title
                slug
              }
            }
            mustRead {
              selection_specialFeatured {
                specialFeatureName
              }
            }
          }
        }
      }
    }
  `)

  function getTagsFromRecipe(recipe, searchName) {
    const hit = recipe.edges.filter(data => {
      return data.node.recipeTitle === searchName
    })
    const tags = hit[0].node.categoryTags.tags.map(data => {
      return data.tagName
    })
    return tags
  }

  const selectionData = topContents.allNewtSelection.edges
  const { topView, pickUp, mustRead } = selectionData[0].node
  const getTag = getTagsFromRecipe(
    recipeData,
    topView.selection_recipe.recipeTitle
  )

  return (
    <section className="bg-[#f9f9f9] pb-16">
      <div className="grid lg:grid-cols-2 max-w-screen-2xl m-auto">
        <div>
          <div id="weekly" className="relative">
            <GetRecipeImage
              recipeData={recipeData}
              searchTitle={topView.selection_recipe.recipeTitle}
            />
            <div className="absolute md:-bottom-12 -bottom-6 left-8">
              <BasicButton
                text={"レシピを見る"}
                link={`/recipe/${topView.selection_recipe.slug}`}
                className={"text-xs md:text-base"}
              />
              <div className="md:text-[clamp(60px,7vw,84px)] text-[10vw] text-white">
                <span className="block text-right font-alphabet leading-snug">
                  WEEKLY
                </span>
                <span className="block text-right font-alphabet leading-snug">
                  TOPIC
                </span>
              </div>
            </div>
          </div>
          <div className="bg-omcLightGray py-12 px-6">
            <p>{topView.selection_recipe.recipeTitle}</p>
            <div className="border-t border-omcGray pt-4 mt-4">
              <GrayBadge text={getTag} />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center md:gap-12 gap-2 px-6 absolute top-6 right-1/2 lg:right-0 2xl:right-1/2  translate-x-1/2 lg:translate-x-0 2xl:translate-x-[90%] z-50">
          <h1>
            <Link
              to={"/"}
              className="w-[clamp(200px,20vw,500px)] mr-auto block drop-shadow-white"
            >
              <StaticImage
                src={Logo}
                alt="ohmycottie"
                layout="constrained"
                width={500}
              />
            </Link>
          </h1>
          <IconMenuBar />
        </div>
        {/* PickUp */}
        <div className="lg:mt-[100px] mt-8 z-10 px-8">
          <h2 className="main_catch lg:-ml-12 text-center lg:text-left">
            <span>Find</span>
            <span>New</span>
            <span>Recipe!</span>
          </h2>
          <p className="pt-4 px-6 lg:px-0 mb-12 ">
            気になっていた”あの料理”のレシピを見つけられるかも?「料理をもっと楽しみたい」を叶えるフードメディアohmycottie。色んな料理に挑戦して、いつものレシピに新しいアイディアを。
          </p>
          <div className="grid md:grid-cols-2 gap-8 pt-8 lg:w-[110%] lg:-ml-[10%]">
            <div className="font-alphabet text-xl row-start-2 md:row-start-1">
              <MakerTitle text={"Pick Up"} />
              <CardSecondary
                link={`/life-style/${pickUp.selection_lifestyle.slug}`}
                btnText={"記事を読む"}
              >
                <GetLifestyleImage title={pickUp.selection_lifestyle.title} />
              </CardSecondary>
              <h3 className="text-sm leading-normal">{pickUp.selection_lifestyle.title}</h3>
            </div>
            <div className="flex md:flex-col flex-col-reverse gap-12 -mt-12">
              <div>
                <MakerTitle text={"Must Read"} />
                <CardSecondary
                  aspectRatio={"3/2"}
                  link={`/special-feature/${mustRead.selection_specialFeatured.specialFeatureName}`}
                  btnText={"記事を読む"}
                >
                  <GetSpecialFeatureImage
                    specialFeatureName={
                      mustRead.selection_specialFeatured.specialFeatureName
                    }
                  />
                </CardSecondary>
                <h3 className="text-sm leading-normal">{mustRead.selection_specialFeatured.specialFeatureName}</h3>
              </div>
              <FeelsLike />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
