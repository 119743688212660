import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"

export default function GetLifestyleImage({ title, className }) {
  const allLifestyle = useStaticQuery(graphql`
    {
      allNewtLifestyle {
        edges {
          node {
            eyecatch {
              src
            }
            title
          }
        }
      }
    }
  `)
  const nodes = allLifestyle.allNewtLifestyle.edges.filter(data => {
    return data.node.title === title
  })
  const post = nodes[0].node
  return <ImageFromNewt src={post.eyecatch.src} className={className && className}/>
}
