import React from "react"
import SearchForm from "../Search/SearchForm"
export default function FeelsLike() {
  return (
    <div>
      <h2>
        <span className="text-3xl font-alphabet">Feels Like...?</span>
      </h2>
      <SearchForm/>
      <p className="text-xs text-omcBlueGray text-right pr-4 pt-2 xl:block hidden">今日の気分でキーワードレレシピ検索</p>
    </div>
  )
}