import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import HeaderTitle from "../HeaderTitle"
import BlackButton from "../button/BlackButton"
import Card from "../Post/Card"

export default function ListedRecipes({ recipesData, title, subTitle }) {
  const data = recipesData

  return (
    <section className="bg-[#f9f9f9] 2xl:px-4 2xl:py-6 2xl:my-6">
      <div className="bg-white max-w-screen-2xl mx-auto 2xl:rounded-[55px] custom-shadow-md">
        <div className="flex flex-col md:flex-row gap-4 justify-between md:items-end mb-8 basic-wrapper">
          <HeaderTitle
            title={title}
            sub={subTitle}
            className="font-alphabet inline-block"
          />
          <BlackButton
            text="すべてを見る"
            link={"/recipe/page/1"}
            className={"text-sm"}
          />
        </div>

        <div className="">
          <Swiper
            spaceBetween={20}
            slidesPerView={1.5}
            initialSlide={1.5}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
                initialSlide: 1.5,
              },
              1024: {
                spaceBetween: 20,
                slidesPerView: 4,
                initialSlide: 2.5,
              },
            }}
            modules={[Pagination]}
            className="mb-10"
            id="latest-recipe"
          >
            {data.map((recipe, index) => {
              const taglist = recipe.node.categoryTags.tags.map(tag => {
                return tag.tagName
              })

              const cook = recipe.node.recipeMeta.cookTime.replace(
                /[^0-9 ]/g,
                ""
              )
              const prep = recipe.node.recipeMeta.prepTime.replace(
                /[^0-9 ]/g,
                ""
              )
              const sum = parseInt(cook) + parseInt(prep)
              const totalTime =
                sum > 59
                  ? Math.floor((sum / 60) * 10) / 10 + " hour"
                  : sum + " min."
              return (
                <div>
                  <SwiperSlide key={index}>
                    <Card
                      title={recipe.node.recipeMeta.recipeName}
                      img={recipe.node.eyecatch.src}
                      tags={taglist}
                      time={totalTime}
                      link={recipe.node.slug}
                      hasVideo={recipe.node.youtubeLink ? true : false}
                    />
                  </SwiperSlide>
                </div>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
