import React from "react"
import ImageFromNewt from "../ImageFromNewt"

export default function GetRecipeImage({ recipeData, searchTitle, className }) {
  const hitContent = recipeData.edges.filter(content => {
    const recipeTitle = content.node.recipeTitle
    const search = searchTitle
    return recipeTitle === search
  })
  const src = hitContent[0].node.eyecatch.src
  return (
    <>
      <ImageFromNewt src={src} alt={searchTitle} className={className && className} />
    </>
  )
}
